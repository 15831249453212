import React from "react";
import classNames from "classnames";

const InputTextarea = ({
  name,
  placeholder,
  required,
  formState,
  hasError,
  id,
  dark,
  className: _className,
}) => {
  const [form, setForm] = formState;

  return (
    <textarea
      name={name}
      id={id || name}
      placeholder={placeholder}
      className={classNames(
        "min-h-[6rem] w-full max-w-full appearance-none border-b-2 bg-transparent pb-2 text-teal-light",
        _className,
        {
          "!border-pink": hasError,
          "border-teal-light text-white": dark,
          "border-black text-teal": !dark,
        }
      )}
      onChange={e => {
        setForm({ ...form, [name]: e.target.value });
      }}
    />
  );
};

InputTextarea.defaultProps = {};

export default InputTextarea;
