import gdpr from "@utils/gdpr";
import { registerMiddleware } from "../lib/middleware";

const gdprCookieName = `${process.env.GATSBY_APP_NAME}_gdpr`;

const reducer = (state, action) => {
  switch (action.type) {
    case "startTransition":
      return {
        ...state,
        isTransitioning: true,
      };

    case "endTransition":
      return {
        ...state,
        isTransitioning: false,
      };

    case "acceptGdpr":
      return {
        ...state,
        gdpr: true,
      };

    case "hideNav":
      return {
        ...state,
        hideNav: true,
      };

    case "showNav":
      return {
        ...state,
        hideNav: false,
      };

    case "setPreviewMode":
      return {
        ...state,
        previewMode: action.previewMode,
      };

    case "setPageSize":
      return {
        ...state,
        pageSize: action.pageSize,
      };

    case "setTheme":
      return {
        ...state,
        theme: action.theme,
      };

    default:
      return state;
  }
};

const { hasGdprConsent, setGdprConsent } = gdpr;

registerMiddleware({
  actionType: "acceptGdpr",
  func: () => {
    setGdprConsent(gdprCookieName);
  },
});

const initialState = {
  gdpr: hasGdprConsent(gdprCookieName),
  previewMode: false,
  isTransitioning: false,
  hideNav: true,
  pageSize: { height: 0, width: 0 },
  theme: "white",
};

export default {
  reducer,
  initialState,
};
