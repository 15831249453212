import React, { useEffect, useState } from "react";
import classNames from "classnames";
import CheckMark from "@svg/check.svg";

const InputCheckbox = ({
  name,
  placeholder,
  required,
  formState,
  hasError,
  checked,
  id,
  value,
  groupState,
  className: _className,
}) => {
  const [form, setForm] = formState;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [checkedList, setCheckedList] = groupState || useState(false);
  const active = groupState ? checkedList?.includes(value) : form[name];
  const updateChecklist = e => {
    const add = e.target.checked;
    if (add) {
      setCheckedList([...checkedList, e.target.value]);
    } else {
      const removeEntry = checkedList?.filter(i => i !== e.target.value);
      setCheckedList(removeEntry);
    }
  };

  useEffect(() => {
    if (groupState) {
      setForm({
        ...form,
        [name]: checkedList,
      });
    }
  }, [checkedList]);

  return (
    <>
      <input
        type="checkbox"
        name={name}
        id={id || name}
        value={value}
        placeholder={placeholder}
        className={classNames("sr-only", _className, {
          "border-pink": hasError,
        })}
        onChange={e => {
          if (groupState) {
            updateChecklist(e);
          } else {
            setForm({
              ...form,
              [name]: e.target.checked ? e.target.value : null,
            });
          }
        }}
        checked={checked}
      />
      <span
        className={classNames(
          "relative flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-[2px] border border-teal transition duration-[250ms]",
          { "bg-teal": active, "bg-white": !active }
        )}
      >
        <CheckMark className="absolute fill-white p-[2px]" />
      </span>
    </>
  );
};

InputCheckbox.defaultProps = {};

export default InputCheckbox;
