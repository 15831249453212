/**
 * Implement Gatsby's SSR (Server Side Rendering) APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/ssr-apis/
 */

/* eslint-disable global-require */

// import styles index
import "./src/pcss/index.pcss";
import { AnimatePresence } from "framer-motion";
import React from "react";
import { AppStateProvider } from "@state";
import Default from "@templates/Default";
import ErrorBoundary from "@organisms/ErrorBoundary";

export const wrapPageElement = ({ element, props }) => {
  const { pageContext } = props;
  return (
    <Default pageContext={pageContext}>
      <AnimatePresence mode="wait" initial={false}>
        {element}
      </AnimatePresence>
    </Default>
  );
};

export const wrapRootElement = ({ element }) => {
  return (
    <ErrorBoundary>
      <AppStateProvider>{element}</AppStateProvider>
    </ErrorBoundary>
  );
};

export const onClientEntry = () => {
  if (process.env.NODE_ENV !== "production") {
    const whyDidYouRender = require("@welldone-software/why-did-you-render");
    whyDidYouRender(React, {
      trackAllPureComponents: true,
    });
  }
};
