/* eslint-disable react/jsx-no-useless-fragment */
import React from "react";
import classNames from "classnames";

const InputText = ({
  name,
  placeholder,
  required,
  formState,
  hasError,
  options,
  dark,
  className: _className,
}) => {
  const [form, setForm] = formState;

  return (
    <select
      name={name}
      id={name}
      className={classNames(
        "custom-select w-full max-w-full appearance-none border-b-2 border-teal-light bg-transparent",
        _className,
        {
          "!border-pink": hasError,
          "border-teal-light text-white": dark,
          "border-black text-teal": !dark,
        }
      )}
      onChange={e => {
        setForm({ ...form, [name]: e.target.value });
      }}
    >
      <>
        {placeholder && (
          <option key="placeholder" value="" className="text-black">
            {placeholder}
          </option>
        )}
        {options?.map((option, i) => {
          return (
            <option
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              value={option}
              className="text-black"
            >
              {option}
            </option>
          );
        })}
      </>
    </select>
  );
};

InputText.defaultProps = {
  dark: true,
};

export default InputText;
