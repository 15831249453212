import React, { Component } from "react";
import loadable from "@loadable/component";

const FallbackPage = loadable(() => import("@templates/FallbackPage"));

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { error: error.message };
  }

  componentDidCatch(error, errorInfo) {
    // eslint-disable-next-line no-console
    console.error(error);
    this.setState({ error: error.message });
  }

  render() {
    const { error } = this.state;
    if (error) {
      return <FallbackPage error={error} />;
    }
    // eslint-disable-next-line react/destructuring-assignment
    return this.props.children;
  }
}

ErrorBoundary.defaultProps = {};

export default ErrorBoundary;
