import React from "react";
import classNames from "classnames";

const InputText = ({
  className: _className,
  condensed,
  dark,
  formState,
  hasError,
  id,
  name,
  placeholder,
  required,
}) => {
  const [form, setForm] = formState;

  return (
    <input
      type="text"
      name={name}
      id={id || name}
      placeholder={placeholder}
      className={classNames("w-full max-w-full appearance-none", _className, {
        "!bg-teal-dark": hasError && dark && !condensed,
        "!bg-teal-light": hasError && !dark && !condensed,
        "!border-teal": hasError && dark && condensed,
        "!border-pink": hasError && !dark && condensed,
        "text-white": dark,
        "text-black": !dark,
        "border-2 bg-white p-2": condensed,
        "border-b-2 bg-transparent pb-2": !condensed,
        "border-teal/0": condensed && dark,
        "border-pink/0": condensed && !dark,
        "border-teal-light": !condensed && dark,
        "border-black": !condensed && !dark,
      })}
      onChange={e => {
        setForm({ ...form, [name]: e.target.value });
      }}
    />
  );
};

export default InputText;
