const reducer = (state, action) => {
  const { type, solution, modules, module: mod } = action;

  switch (type) {
    case "clearSolution":
      return { ...state, solution: undefined, modules: [] };
    case "setSolution":
      return { ...state, solution, modules: modules || state.modules };
    case "toggleModule":
      // remove mod if it's in list
      if (state.modules.includes(mod)) {
        return {
          ...state,
          modules: state.modules.filter(mods => mods !== mod),
        };
      }
      // add it if not
      return { ...state, modules: [...state.modules, mod] };
    default:
      return state;
  }
};

const initialState = {
  solution: undefined,
  modules: [],
};

export default {
  reducer,
  initialState,
};
